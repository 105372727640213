/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useExpressismOfferPreview = () => {
  const expressismOfferImage = useStaticQuery(graphql`
    query expressismOfferImg {
      exp: file(relativePath: { eq: "expressism/expressism-offer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const offerImage = expressismOfferImage.exp.childImageSharp.fluid;

  return offerImage;
};

export default useExpressismOfferPreview;
