// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import MasterClassContent from "components/pages/course-page/master-class-content";
// assets
import ogImage from "assets/images/seo/expressism.jpg";
import ogImageVK from "assets/images/seo/vk/expressism.jpg";
// data
import localData from "data/pages/expressism";
import teamData from "data/team";
// hooks
import useExpressismPortfolio from "hooks/portfolio/useExpressismPortfolio";
import useExpressismOfferPreview from "hooks/useExpressismOfferPreview";
import useExpressismQuery from "hooks/graphql/useExpressismQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";

// 15.03.2024 Экспрессизм скрыли
const ExpressismPage = () => {
  const prismicData = useExpressismQuery();
  const portfolio = useExpressismPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};

  const vadim = teamData.find(({ name }) => name === "Вадим Гранич");
  vadim.category = "Арт-директор";

  const sasha = teamData.find(({ name }) => name === "Саша Буяк");
  sasha.category = "Автор";

  const authors = [
    {
      authorInfo: sasha,
      authorDescription: (
        <>
          <p>Графический дизайн и исследование</p>
          <p>Запись и ведение видеолекций</p>
        </>
      ),
    },
    {
      authorInfo: vadim,
      authorDescription: (
        <>
          <p>Арт-директор при разработке графсистемы</p>
        </>
      ),
    },
  ];

  const offerImage = useExpressismOfferPreview();

  return (
    <Layout logo="expressism">
      <div className="section-top-block" />
      <SEO
        title="Графсистема Экспрессизм"
        description={
          seoData?.seodescription?.text ||
          "Графсистема, основанная на Экспресс-дизайне студии Артемия Лебедева. Добро пожаловать во взрослые дизайнерские мультики."
        }
        keywords={["экспрессизм", "графсистема", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemExpressism)}
        noIndex
      />
      <MasterClassContent
        disable
        expressism
        offerWithPreview={offerImage}
        courseOffer={{ data }}
        courseExplanations={{ data: localData.ExpressismExplanations }}
        courseTitle={data.coursetitle?.text}
        coursePortfolio={{
          pinterestText: "Экспрессизм в Энциклопедии\nГрафдизайна Школы",
          pinterestLink: "https://www.pinterest.ru/vadim_granich/expressism/",
          portfolio,
          graphicSystemName: "Экспрессизм",
        }}
        coursePriceRange={{
          formId: "ltForm4630442",
          valueInputs: [
            {
              id: "4373794",
              price: 900,
            },
            {
              id: "4373798",
              price: 1800,
            },
            {
              id: "4373803",
              price: 2500,
            },
            {
              id: "4373807",
              price: 3500,
            },
          ],
          formPostUrl:
            "https://school.granich.design/pl/lite/block-public/process-html?id=1690362208",
        }}
        authors={authors}
      />
    </Layout>
  );
};

export default ExpressismPage;
