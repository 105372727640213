/* eslint-disable */
import defaultPriceRange from "./default-price-range";
const priceRange = [
  {
    ...defaultPriceRange[0],
    price: 900,
    getcourseInputId: "4373794",
  },
  {
    ...defaultPriceRange[1],
    price: 1800,
    getcourseInputId: "4373798",
  },
  {
    ...defaultPriceRange[2],
    price: 2500,
    getcourseInputId: "4373803",
  },
  {
    ...defaultPriceRange[3],
    price: 3500,
    getcourseInputId: "4373807",
  },
];
export default {
  ExpressismExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle: "",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/expressism/explanations/expressism-02.jpg",
      },
      explanationsTitle: "Что такое графсистема Экспрессизм?",
      explanationsTextTitle: "",
      explanationsTextDescription:
        "Всё началось с уже ставшего легендарным логотипа бургерной Джон Фёдор. Кто-то скажет, что это шутка, вышедшая из под контроля. Кто-то считает это гениальной пиар-акцией. Мы же считаем это переосмыслением трудов советских художников 20 века (конструктивизм, супрематизм, лирический абстракционизм). Добро пожаловать во взрослые дизайнерские мультики.",
    },
  ],
  priceRange,
};
